const ios = 'https://apps.apple.com/us/app/your-app-name/id1234567890' // change later
const android = 'https://play.google.com/store/apps/details?id=com.anonymous.pikmeTV'
const link = /iPhone|iPad|iPod/.test(navigator.userAgent) ? ios : android

export function PopupApp() {

    return <div className="bought" style={{ height: 'unset' }}>
        <h1>השתמשו באפליקציה לחוויה מיטבית!</h1>
        <p>אנו מזהים שאתם גולשים ממכשיר נייד. הורידו את האפליקציה שלנו ותהנו מגלישה מהירה ונוחה יותר.</p>
        <a href={link}><div className="done undecorate">לאפליקציה</div></a>
    </div>
}